// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/textarea.ts

const Textarea = {
  baseStyle: {
    borderRadius: "4px",
    transition: 0
  },
  sizes: {
    sm: {
      borderRadius: "4px",
    },
    md: {
      borderRadius: "4px",
    },
    lg: {
      borderRadius: "4px",
    },
  },
}

export default Textarea
