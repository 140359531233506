// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/button.ts

const Button = {
  // The styles all button have in common
  baseStyle: {
    borderRadius: "4px",
    fontWeight: "500"
  }
}

export default Button