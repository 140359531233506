// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/alert.ts

const Alert = {
  parts: ["description", "icon"],
  baseStyle: {
    description: {
      fontSize: "sm",
      width: "100%"
    },
  },
  variants: {
    subtle: {
      icon: {
        color: "gray.500",
      }
    }
  }
}

export default Alert
