// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/menu.ts

const Menu = {
  parts: ["list", "button"],
  baseStyle: {
    list: {
      borderRadius: "4px",
      p: "0"
    },
    button: {
      borderRadius: "4px"
    }
  },
}

export default Menu
