// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/modal.ts

const Modal = {
  parts: [
    "overlay",
    "dialogContainer",
    "dialog",
    "header",
    "closeButton",
    "body",
    "footer",
  ],
  baseStyle: {
    overlay: {
      bg: "linear-gradient(0deg, rgba(0,0,0,.25) 0%, rgba(0,0,0,0.25) 25%, rgba(0,0,0,0.25) 100%)"
    },
    dialogContainer: {
    },
    dialog: {
      borderRadius: "4px",
      px: 0,
      overflow: "hidden"
    },
    header: {
      borderBottom: "1px",
      bg: "gray.100",
      borderColor: "gray.200",
    },
    body: {
      px: 6,
      pt: 6,
    },
    footer: {
      pt: 6,
    },
    closeButton: {
      color: "gray.400",
      top: 4,
      px: 6,
    },
  },
  sizes: {
    sm: {
      dialog: {
        borderRadius: "4px",
      },
    },
    md: {
      dialog: {
        borderRadius: "4px",
      },
    },
    lg: {
      dialog: {
        borderRadius: "4px",
      },
    },
  },
}

export default Modal
