// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/avatar.ts

const Avatar = {
  parts: ["badge"],
  baseStyle: {
    badge: {
      transform: "translate(25%, -125%)"
    },
  },
}

export default Avatar
