// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/number-input.ts

const NumberInput = {
  parts: ["field"],
  baseStyle: {
    field: {
      borderRadius: "4px"
    }
  },
  sizes: {
    sm: {
      field: {
        borderRadius: "4px"
      }
    },
    md: {
      field: {
        borderRadius: "4px"
      }
    },
    lg: {
      field: {
        borderRadius: "4px"
      }
    }
  }
}

export default NumberInput
