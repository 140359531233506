// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/input.ts

const Select = {
  parts: ["field"],
  baseStyle: {
    field: {
      borderRadius: "4px"
    }
  },
  sizes: {
    sm: {
      field: {
        borderRadius: "4px"
      }
    },
    md: {
      field: {
        borderRadius: "4px"
      }
    },
    lg: {
      field: {
        borderRadius: "4px"
      }
    }
  }
}

export default Select
