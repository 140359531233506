// src/@chakra-ui/gatsby-plugin/theme.js

import { extendTheme } from '@chakra-ui/react';

// NOTE:  these are maintained over at cc-web-app too

// Component style overrides
import Alert from './components/Alert';
import Avatar from './components/Avatar';
import Badge from './components/Badge';
import Breadcrumb from './components/Breadcrumb';
import Button from './components/Button';
import Form from './components/Form';
import FormLabel from './components/FormLabel';
import Input from './components/Input';
import Menu from './components/Menu';
import Modal from './components/Modal';
import NumberInput from './components/NumberInput';
import Select from './components/Select';
import Tabs from './components/Tabs';
import Tag from './components/Tag';
import Textarea from './components/Textarea';
import Tooltip from './components/Tooltip';

import colors from './colors';

const theme = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  // styles,
  // borders,
  colors: {
    ...colors,
    text: colors.black,
    textContrast: colors.black,
    textGray: colors.gray[600],
    background: colors.white,
    background50: colors.gray[50],
    background100: colors.gray[100],
    background200: colors.gray[200],
    brandSubtle: colors.gray[400],
    primary: colors.blue[500],
    pri: { ...colors.blue },
    secondary: colors.purple[600],
    sec: { ...colors.purple },
    action: colors.green[600],
    act: { ...colors.green }
  },
  // Removed fonts so they are just defined in tailwind
  fonts: {
    // https://chakra-ui.com/docs/theming/theme#typography
    heading: 'Inter, Arial, serif',
    body: 'Inter, Arial, sans-serif',
    mono: 'Source Code Pro, monospace'
  },
  // Other foundational style overrides go here
  components: {
    Alert,
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Form,
    FormLabel,
    Input,
    NumberInput,
    Menu,
    Tooltip,
    Modal,
    Select,
    Tag,
    Tabs,
    Textarea
  },
  textStyles: {
    logo: {
      fontSize: 'xl',
      fontWeight: 'bold'
    },
    body: {
      size: 'lg',
      lineHeight: 'tall'
    },
    hero: {
      fontSize: ['2xl', '3xl', '5xl'],
      fontWeight: 'bold'
    },
    heroSub: {
      fontSize: ['md', 'lg', 'xl']
    },
    header1: {
      fontSize: ['2xl', null, '3xl'],
      fontWeight: 'bold'
    },
    header2: {
      fontSize: ['lg', null, 'xl'],
      fontWeight: 'bold'
    },
    subheader: {
      fontSize: ['md', null, 'lg'],
      fontWeight: 'bold'
    },
    accent: {
      fontFamily: 'mono',
      letterSpacing: 'wider'
    },
    accentHeader: {
      fontFamily: 'mono',
      fontWeight: 'bold',
      letterSpacing: 'wider',
      fontSize: ['xs', null, 'sm'],
      textTransform: 'uppercase'
    }
  }
};

const ChakraTheme = extendTheme(theme);

export default ChakraTheme;
