// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/input.ts

const Input = {
  parts: ["field", "addon"],
  baseStyle: {
    field: {
      borderRadius: "4px",
      transition: 0
    }
  },
  variants: {
    unstyled: {},
    filled: {},
    flushed: {}
  },
  sizes: {
    sm: {
      field: {
        borderRadius: "4px"
      },
      addon: {
        borderRadius: "4px"
      }
    },
    md: {
      field: {
        borderRadius: "4px"
      },
      addon: {
        borderRadius: "4px"
      }
    },
    lg: {
      field: {
        borderRadius: "4px"
      },
      addon: {
        borderRadius: "4px"
      }
    }
  }
}

export default Input
