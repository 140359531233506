// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/breadcrumb.ts

const Breadcrumb = {
  parts: ["container", "item", "link", "separator"],
  baseStyle: {
    link: {
      // fontSize: "1rem"
    },
  },
}

export default Breadcrumb
