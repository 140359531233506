const gradients = [
  'linear-gradient(145deg,#60A5FA 0%,#2563EB 50%)',
  'gradient(113.96deg, #34D399 9.9%, #22D3EE 61.98%)',
  'linear-gradient(113.96deg, #C4B5FD 9.9%, #6D28D9 61.98%)',
  'linear-gradient(113.96deg, #6EE7B7 9.9%, #047857 61.98%)'
];

const commonButtonStyle = {
  padding: '.8rem 1.25rem',
  borderRadius: 4,
  whiteSpace: 'nowrap',
  // padding: '4px 24px',
  // fontFamily: 'monospace',
  fontSize: 2,
  fontWeight: 'heading',
  lineHeight: 'heading',
  textDecoration: 'none',
  '&:hover': {
    opacity: 0.8,
    cursor: 'pointer'
  }
};

const theme = {
  colors: {
    text: '#000000',
    textContrast: '#FFFFFF',
    textGray: '#4B5563',
    background: '#FFFFFF',
    background100: '#E5E7EB',
    primary: '#3B82F6',
    secondary: '#24D3DF',
    dark: '#111827',
    accent: '#9CA3AF',
    brandSubtle: '#9CA3AF',
    'gray-900': '#111827',
    'gray-800': '#1F2937',
    'gray-700': '#374151',
    'gray-600': '#4B5563',
    'gray-500': '#6B7280',
    'gray-400': '#9CA3AF',
    'gray-300': '#D1D5DB',
    'gray-200': '#E5E7EB',
    'gray-100': '#F3F4F6',
    'gray-50': '#F9FAFB',
    'red-900': '#7F1D1D',
    'red-800': '#991B1B',
    'red-700': '#B91C1C',
    'red-600': '#DC2626',
    'red-500': '#EF4444',
    'red-400': '#F87171',
    'red-300': '#FCA5A5',
    'red-200': '#FECACA',
    'red-100': '#FEE2E2',
    'red-50': '#FEF2F2',
    'orange-900': '#7C2D12',
    'orange-800': '#9A3412',
    'orange-700': '#C2410C',
    'orange-600': '#EA580C',
    'orange-500': '#F97316',
    'orange-400': '#FB923C',
    'orange-300': '#FDBA74',
    'orange-200': '#FED7AA',
    'orange-100': '#FFEDD5',
    'orange-50': '#FFF7ED',
    'yellow-900': '#78350F',
    'yellow-800': '#92400E',
    'yellow-700': '#B45309',
    'yellow-600': '#D97706',
    'yellow-500': '#F59E0B',
    'yellow-400': '#FBBF24',
    'yellow-300': '#FCD34D',
    'yellow-200': '#FDE68A',
    'yellow-100': '#FEF3C7',
    'yellow-50': '#FFFBEB',
    'green-900': '#064E3B',
    'green-800': '#065F46',
    'green-700': '#047857',
    'green-600': '#059669',
    'green-500': '#10B981',
    'green-400': '#34D399',
    'green-300': '#6EE7B7',
    'green-200': '#A7F3D0',
    'green-100': '#D1FAE5',
    'green-50': '#ECFDF5',
    'teal-900': '#134E4A',
    'teal-800': '#115E59',
    'teal-700': '#0F766E',
    'teal-600': '#0D9488',
    'teal-500': '#14B8A6',
    'teal-400': '#2DD4BF',
    'teal-300': '#5EEAD4',
    'teal-200': '#99F6E4',
    'teal-100': '#CCFBF1',
    'teal-50': '#F0FDFA',
    'cyan-900': '#164E63',
    'cyan-800': '#155E75',
    'cyan-700': '#0E7490',
    'cyan-600': '#0891B2',
    'cyan-500': '#06B6D4',
    'cyan-400': '#22D3EE',
    'cyan-300': '#67E8F9',
    'cyan-200': '#A5F3FC',
    'cyan-100': '#CFFAFE',
    'cyan-50': '#ECFEFF',
    'light-blue-900': '#0C4A6E',
    'light-blue-800': '#075985',
    'light-blue-700': '#0369A1',
    'light-blue-600': '#0284C7',
    'light-blue-500': '#0EA5E9',
    'light-blue-400': '#38BDF8',
    'light-blue-300': '#7DD3FC',
    'light-blue-200': '#BAE6FD',
    'light-blue-100': '#E0F2FE',
    'light-blue-50': '#F0F9FF',
    'blue-900': '#1E3A8A',
    'blue-800': '#1E40AF',
    'blue-700': '#1D4ED8',
    'blue-600': '#2563EB',
    'blue-500': '#3B82F6',
    'blue-400': '#60A5FA',
    'blue-300': '#93C5FD',
    'blue-200': '#BFDBFE',
    'blue-100': '#DBEAFE',
    'blue-50': '#EFF6FF',
    'indigo-900': '#312E81',
    'indigo-800': '#3730A3',
    'indigo-700': '#4338CA',
    'indigo-600': '#4F46E5',
    'indigo-500': '#6366F1',
    'indigo-400': '#818CF8',
    'indigo-300': '#A5B4FC',
    'indigo-200': '#C7D2FE',
    'indigo-100': '#E0E7FF',
    'indigo-50': '#EEF2FF',
    'purple-900': '#4C1D95',
    'purple-800': '#5B21B6',
    'purple-700': '#6D28D9',
    'purple-600': '#7C3AED',
    'purple-500': '#8B5CF6',
    'purple-400': '#A78BFA',
    'purple-300': '#C4B5FD',
    'purple-200': '#DDD6FE',
    'purple-100': '#EDE9FE',
    'purple-50': '#F5F3FF',
    'pink-900': '#831843',
    'pink-800': '#9D174D',
    'pink-700': '#BE185D',
    'pink-600': '#DB2777',
    'pink-500': '#EC4899',
    'pink-400': '#F472B6',
    'pink-300': '#F9A8D4',
    'pink-200': '#FBCFE8',
    'pink-100': '#FCE7F3',
    'pink-50': '#FDF2F8',
    'rose-900': '#881337',
    'rose-800': '#9F1239',
    'rose-700': '#BE123C',
    'rose-600': '#E11D48',
    'rose-500': '#F43F5E',
    'rose-400': '#FB7185',
    'rose-300': '#FDA4AF',
    'rose-200': '#FECDD3',
    'rose-100': '#FFE4E6',
    'rose-50': '#FFF1F2'
  },
  gradients,
  buttons: {
    primary: {
      ...commonButtonStyle,
      color: 'textContrast',
      background: 'primary'
    },
    secondary: {
      ...commonButtonStyle,
      color: 'primary',
      background: 'white',
      borderColor: 'primary',
      border: 'solid 1px'
    },
    outline: {
      ...commonButtonStyle,
      color: 'primary',
      background: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid'
    },
    contrast: {
      ...commonButtonStyle,
      color: 'text',
      background: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid'
    },
    link: {
      ...commonButtonStyle,
      color: 'primary',
      background: 'transparent',
      // fontFamily: 'monospace',
      borderWidth: 0
    }
  },
  forms: {
    input: {
      borderRadius: 4,
      borderWidth: '1px',
      '&:focus': {
        outline: 'primary',
        borderColor: 'primary'
      }
    },
    select: {
      borderRadius: 4,
      borderWidth: '1px',
      '&:focus': {
        outline: 'primary',
        borderColor: 'primary'
      }
    },
    textarea: {
      borderRadius: 4,
      fontFamily: 'body',
      borderWidth: '1px',
      '&:focus': {
        outline: 'primary',
        borderColor: 'primary'
      }
    },
    label: {},
    radio: {},
    checkbox: {
      color: 'black'
    }
  },
  breakpoints: ['40em', '52em', '64em'],
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'Inter, system-ui, sans-serif',
    monospace: 'Source Code Pro, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 30, 32, 36, 48, 52, 60, 90],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700
  },
  lineHeights: {
    body: 1.33,
    heading: 1.1,
    subheading: 1,
    'box-height': 0.75
  },
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [2, 3]
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [5, 6, 7]
    },
    subheading: {
      fontFamily: 'body',
      lineHeight: 'body',
      color: 'text',
      fontWeight: 'heading',
      fontSize: [2, 3, 4],
      mb: 4
    },
    mono: {
      fontFamily: 'monospace',
      letterSpacing: '1px',
      textTransform: 'uppercase'
    },
    submono: {
      fontFamily: 'monospace',
      letterSpacing: '1px'
    },
    heroHeading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: [6, 7, 8],
      my: 3
    },
    heroSubheading: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [2, 3, 3],
      mb: 4
    },
    heroParagraph: {
      fontFamily: 'monospace',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [2, 2, 2],
      my: 3
    },
    giant: {
      fontSize: [7, 8, 9, 10],
      fontWeight: 900
    },
    label: {
      fontSize: 2,
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    button: {
      fontFamily: 'body',
      lineHeight: 'body',
      whiteSpace: 'nowrap',
      fontWeight: 'body',
      fontSize: 2
    },
    renderedMarkdown: {
      h1: {
        fontSize: [5, 6, 7],
        mb: 2
      },
      h2: {
        fontSize: [4, 5, 5],
        marginTop: 4,
        marginBottom: -2
      },
      h3: {
        fontSize: [3, 4, 4],
        marginTop: 4,
        marginBottom: -2
      },
      a: {
        color: 'primary'
      }
    }
  },
  link: {
    nav: {
      textDecoration: 'none',
      fontWeight: 'heading',
      color: 'textGray'
    },
    footer: {
      textDecoration: 'none',
      color: 'inherit'
    },
    primary: {
      textDecoration: 'none',
      color: 'primary',
      fontWeight: 'heading'
    },
    secondary: {
      textDecoration: 'none',
      color: 'secondary',
      fontWeight: 'heading'
    }
  },
  ul: {
    footer: {
      padding: 0
    }
  },
  li: {
    footer: {
      listStyleType: 'none',
      my: 3,
      color: 'background100'
    }
  },
  hr: {
    primary: {
      width: '100%',
      backgroundColor: 'primary',
      border: 0,
      margin: 0,
      height: '2px',
      my: 4
    },
    secondary: {
      width: '100%',
      backgroundColor: 'secondary',
      border: 0,
      margin: 0,
      height: '2px',
      my: 4
    }
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      backgroundColor: 'background'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: ['heading'],
      fontSize: [4, 6, 7]
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [3, 5]
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [2, 4]
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontSize: [2, 3, 3],
      fontWeight: 'body',
      lineHeight: 'body',
      margin: 4
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      '&:visited': {
        color: 'primary'
      }
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  },
  variants: {
    card: {
      textDecoration: 'none',
      color: 'inherit',
      borderRadius: 4,
      boxShadow: 'card',
      '&.hoverable:hover': {
        transform: 'translateY(-2px)',
        cursor: 'pointer',
        boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.25)'
      }
    }
  }
};

export default theme;
