// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/tag.ts

const Tag = {
  parts: ["container", "label", "closeButton"],
  baseStyle: {
    container: {
      borderRadius: "4px",
      p: "2"

    }
  },
  sizes: {
    xs: {
      container: {
        p: "1",
        borderRadius: "4px"
      }
    },
    sm: {
      container: {
        borderRadius: "4px"
      }
    },
    md: {
      container: {
        borderRadius: "4px"
      }
    },
    lg: {
      container: {
        borderRadius: "4px"
      }
    }
  }
}

export default Tag
