// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tooltip.ts

const Tooltip = {
  baseStyle: {
    tabpanel: {
      p: "0",
    },
    px: "1rem",
    py: ".5rem",
    borderRadius: "4px",
    fontWeight: "medium",
    fontSize: "sm",
    boxShadow: "0",
    maxW: "320px",
    zIndex: "tooltip",
  },

}

export default Tooltip
