// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/badge.ts

const Badge = {
  baseStyle: {
    fontFamily: "mono",
    fontSize: "sm",
    fontWeight: "600",
    // letterSpacing: "1px",
    // textTransform:"Capitalize",
    borderRadius: "4px"
  }
}

export default Badge