// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/tabs.ts


const Tabs = {
  parts: ["root", "tablist", "tab", "tabpanel", "indicator"],
  baseStyle: {
    tabpanel: {
      p: "0",
    },
    tab: {
      _focus: {
        zIndex: 1,
        boxShadow: "0",
      },
    },
  },
  variants: {
    line: {
      // tab: {
      //   color:"gray.400",
      //   fontSize: "md",
      //   // textTransform: "uppercase",
      //   // letterSpacing: "1px",
      //   // fontWeight: "bold",
      //   // py: "0"
      //   // mt: "0px",
      //   // pt: "0px",
      //   // pb: "0px",
      //   // mb: "-4px",
      //   borderBottomWidth: "2px",
      //   // border: "1px solid",
      //   // borderColor: "red.400",
      //   // color: "red.400"
      //   _selected: {
      //     color: "blue.500",
      //     borderColor: "blue.500",
      //   },
      //   _active: {
      //     background: "white",
      //   },
      //   _focused: {
      //     color: "blue.500",
      //     borderColor: "blue.500",
      //   },
      // }
    }
  }
}

export default Tabs
